import styles from './Popover.module.scss';
import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popper as ReactPopper } from 'react-popper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Popover = ({
  children,
  closeable = true,
  show,
  onExit,
  target,
  showOverlay = false,
  placement = 'auto',
  hideArrow = true,
}) => {
  const baseRef = useRef(null);
  const [referenceElement, setReferenceElement] = React.useState(null);
  
  /* istanbul ignore next */
  const closeOnBackdrop = e => {
    closeable && e.target === e.currentTarget && onExit();
  };

  /* istanbul ignore next */
  const escExit = useCallback(event => {
    if([27].includes(event.keyCode)) {
      onExit();
    }
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    if (target) {
      const element = document.querySelectorAll(`#${target}`);
      if (element && element.length > 0)
        setReferenceElement(element[0]);
    }
  }, [referenceElement]);

  /* istanbul ignore next */
  useEffect(() => {
      document.addEventListener('keydown', escExit, false);
    return () => {
      document.removeEventListener('keydown', escExit, false);
    };
  }, []);

  const extendedModifiers = [
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [0, 3],
      },
    },
  ];

  const arrowProps = [
    {
      name: 'arrow',
      enabled: true,
      options: {
        padding: 5,
      },
    }];
  return (
    <div
      ref={baseRef}
      id="popover-base"
      className={classNames('popover-base', styles.popoverBase, showOverlay ? styles.popoverOverlay : null)}
      onMouseDown={closeOnBackdrop}
      aria-label="Popup Open"
      aria-hidden="false"
    >
      <ReactPopper
        arrowProps={arrowProps}
        referenceElement={referenceElement}
        modifiers={extendedModifiers}
        placement={placement}
      >
        {({ ref, style, placement, arrowProps }) => {
    /* istanbul ignore next */
          return (
            <div ref={ref} id="popover" style={style} className={classNames(styles.popover, 'popover')} x-placement={placement}>
              <div id="popover-inner" role="tooltip">
                <div id="popover-body" className={styles.popoverBody}>
                  {children}
                </div>
              </div>
              {!hideArrow && (
                <span ref={arrowProps.ref} className={classNames(styles.arrow, styles[`arrow-placement-${placement}`])} style={arrowProps.style} />
              )}
              <div id="popover-header">
                <FontAwesomeIcon id="popover-close" role={'button'} title="Close" tabIndex={0} icon={faTimes} size="1x" className={classNames(styles.btnClose)} 
                  onClick={()=> onExit()}
                  onKeyDown={escExit}
                />
              </div>
            </div>
          );
        }}
      </ReactPopper>
    </div>
  );
};

Popover.propTypes = {
  children: PropTypes.node,
  closeable: PropTypes.bool,
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  onExit: PropTypes.func,
  target: PropTypes.string,
  showOverlay: PropTypes.bool,
  placement: PropTypes.string,
  hideArrow: PropTypes.bool,
};

export default Popover;