import _ from 'lodash';

const generateRandomNumber = () => {
  return Math.random().toString(36).substr(2, 5);
};

const getProductIds = (hotspots = []) => {
  const ids = _.flatMap(hotspots, ({ product }) => {
    return product.id;
  });
  return ids;
};

export { generateRandomNumber, getProductIds };
