import React from 'react';
import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SimpleText } from 'sana/texts';
import styles from './ActiveOnFocusButton.module.scss';

const ActiveOnFocusButton = ({ onClick, textKey, align='topLeft' }) => {
  return (
    <button
      onClick={onClick}
      tabIndex={0}
      className={classNames(styles.visuallyHidden, styles.activeOnFocus, styles[align])}
    >
      <span>&nbsp;</span>
      <SimpleText textKey={textKey} />
    </button>
  );
};

ActiveOnFocusButton.propTypes = {
  model: PropTypes.object,
  textKey: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['topLeft', 'topRight','bottomLeft', 'bottomRight' ]).isRequired,
  onClick: PropTypes.func,
};

export default memo(ActiveOnFocusButton);