import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProductTile from '../productTile/ProductTile';

const ExplodedView = ({ hotspots, styles, blockId, productIndex, noImage }) => {
  const itemRefs = {};

   /* istanbul ignore next */
  useEffect(() => {
    if (productIndex !== false) {
      const elementId = `item-${blockId}-${hotspots[productIndex].productId}`;
      itemRefs[elementId].scrollIntoView({
        behavior: 'smooth', 
        block: 'center',
        inline: 'nearest',
      });
      setTimeout(() => {
        const id = `item-name-${blockId}-${hotspots[productIndex].productId}`;
        document.getElementById(id).focus();
      }, 100);
    }
  }, [productIndex]);
       
  /* istanbul ignore next */
  function focusToNextHotSpot() {
    if(hotspots.length > productIndex+1){
        const Id = `hotspot-block-${blockId}-${productIndex+1}`;
        setTimeout(() => {
          document.getElementById(Id).focus();
        }, 100);
    }
  }

  /* istanbul ignore next */
  function renderList(hotspots, productIndex, styles) {
    const $element = [];
    if (hotspots && Object.keys(hotspots).length > 0) {
      const uniqProduct = [];
      hotspots.forEach(item => {
        const i = uniqProduct.findIndex(x => x.productId === item.productId);
        if (i <= -1) {
          uniqProduct.push(item);
        }
      });
       /* istanbul ignore next */
      uniqProduct.map(hotspot => {
        const Id = `item-${blockId}-${hotspot.productId}`;
        $element.push(
          <li
            key={Id}
            ref={el => (itemRefs[Id] = el)}
            className={classNames(
              'item-product',
              styles.product,
              Id,
              productIndex !== false && hotspots[productIndex].productId === hotspot.productId && styles.selected,
            )}
          >
            <ProductTile
              model={hotspot.product} 
              styleName="explode"
              defulatImage={noImage}
              isExploded
              isSelect={productIndex !== false && hotspots[productIndex].productId === hotspot.productId}
              blockId={blockId}
            />
            <div
              tabIndex={0}
              aria-hidden
              onFocus={()=>{
                focusToNextHotSpot();
              }}
            />
          </li>,
        );
        return true;
      });
    }
    return $element;
  }

  return (
    <>
      <ul className={classNames(styles.productList, 'product-list')} id={`${blockId}-productlist`}>
        {renderList(hotspots, productIndex, styles)}
      </ul>
    </>
  );
};

ExplodedView.propTypes = {
  hotspots: PropTypes.array,
  styles: PropTypes.object,
  blockId: PropTypes.string,
};
export default ExplodedView;
