import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './ProductTitle.module.scss';
import { Link, ProductPrice, ProductOrderButton, ResponsiveLazyImage } from 'sana/elements';
import { productPage } from 'sana/routes';
import classNames from 'classnames';

const ProductTile = ({ model, styleName, defulatImage, blockId }) => {

  /* istanbul ignore next */
  const to = useCallback(() => productPage.bind(null, model.id), [model.id]);
  const isConfigurable =
    model.productConfiguratorInfo &&
    model.productConfiguratorInfo.isProductConfigurable;
  const productImage = model.image && model.image.medium ? model.image.medium : defulatImage;
  const productTitleId = `item-name-${blockId}-${model.id}`;
  const refProductTitleId = useRef(null);

  /* istanbul ignore next */
  useEffect(() => {
    setTimeout(() => {
      refProductTitleId.current.focus();
    }, 100);
  }, []);

  function onKeyDownHandler(event) {
    /* istanbul ignore next */
    if (['Enter', ' '].includes(event?.key)) {
      window.location.href = model.url;
    }
  }

  /* istanbul ignore next */
  return (
    <div aria-live="polite"
      aria-busy="true"
      tabIndex={-1}
      className={classNames('product-tile', styles.productTile, styleName === 'popup' ? styles.popup : styles.explode)}
    >
      <div className={styles.productInner}>
        <Link url={model.url} to={to} tabIndex={-1}>
          <ResponsiveLazyImage
            src={productImage}
            alt={model.title}
            tabIndex={-1}
            aria-hidden
          />
        </Link>
      </div>
      <div tabIndex={-1} className={styles.productDetail}>
        <div tabIndex={-1} className={styles.productDescription}>
        <Link
          id={productTitleId}
          ref={refProductTitleId}
          className="h4"
          to={to}
          onKeyDown={onKeyDownHandler} url={model.url}
          tabIndex={0}
          aria-label={model.title}
        >
          {model.title}
        </Link>
        <label tabIndex={-1} aria-label={`Item No ${model.id}`} className={styles.itemNumber}>Item No. {model.id}</label>
        </div>
        <div tabIndex={-1} className={styles.checkout}>
          <span tabIndex={-1} aria-label={model.price} className={styles.prices}>
            <ProductPrice salesPrice={model.price} basePrice={model.listPrice} />
          </span>
          <ProductOrderButton
            productId={model.id}
            url={model.url}
            to={to}
            uom={model.uom}
            isOrderable={model.isOrderable}
            isConfigurable={isConfigurable}
            hasVariants={model.hasVariants}
            tabIndex={0}
          />
        </div>
      </div>
    </div>
  );
};

ProductTile.propTypes = {
  model: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    Price: PropTypes.number,
  }),
  styleName: PropTypes.string,
  defulatImage: PropTypes.string,
  blockId: PropTypes.string,
};

export default ProductTile;
