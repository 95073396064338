export const PRODUCT_SET_REQUESTED = 'LOOK_BOOK/PRODUCT_SET_REQUESTED';
export const requestProductSet = (
  contentBlockId, 
  productSetId, 
  options,
) => ({
  type: PRODUCT_SET_REQUESTED,
  payload: { contentBlockId, productSetId, options },
});

export const PRODUCT_SET_RECEIVED = 'LOOK_BOOK/PRODUCT_SET_RECEIVED';
export const productSetReceived = (contentBlockId, products) => ({
  type: PRODUCT_SET_RECEIVED,
  payload: { contentBlockId, products },
});