import { ofType } from 'redux-observable';
import { map, switchMap, pluck, groupBy, mergeMap } from 'rxjs/operators';
import { PRODUCT_SET_REQUESTED, productSetReceived } from './actions';
import { productsQuery } from './queries';

export default (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(PRODUCT_SET_REQUESTED),
    groupBy(action => action.payload.contentBlockId),
    mergeMap(group =>
      group.pipe(
        switchMap(
          ({
            payload: { contentBlockId, productSetId, options },
          }) => {

            const variables = {
              id: productSetId,
              options,
            };

            return api.graphApi(productsQuery, variables).pipe(
              pluck('catalog', 'productSet', 'products'),
              map(products =>
                productSetReceived(
                  contentBlockId,
                  getProductSetProducts(products),
                ),
              ),
            );
          },
        ),
      ),
    ),
  );

  function getProductSetProducts(products) {
    return products;
  }
};