import React from 'react';
import PropTypes from 'prop-types';
import styles from '../LookBook.module.scss';
import Popover from '../popover';
import ProductTile from '../productTile/ProductTile';

const Hotspot = ({
  initialModel,
  index,
  hotspot,
  icon,
  activeIcon,
  setProductIndex,
  productIndex,
  noImage,
  blockId,
}) => {
 
  const { productViewType } = initialModel;

  function onKeyDownHandler(event) {
    if (['Enter', ' '].includes(event?.key)) {
      setProductIndex(index);
    }
  }
  function onFocusHandler(event) {
    setProductIndex(false);
  }

  return (
    <>
      <img
        src={productViewType === 'exploded' && index === productIndex ? activeIcon : icon}
        id={`hotspot-block-${blockId}-${index}`}
        className={styles.hotspotIcon}
        onClick={() => {
          setProductIndex(index);
        }}
        onKeyDown={onKeyDownHandler}
        onFocus={onFocusHandler}
        alt={hotspot.product.title}
        role="button"
        tabIndex={0}
        aria-hidden={'false'}
      />

      { productViewType === 'tile' &&
        hotspot.product &&
        index === productIndex ? (
          <Popover
            show={productIndex}
            target={`hotspot-block-${blockId}-${index}`}
            closeable
            showOverlay={false}
            onExit={() => setProductIndex(false)}
            hideArrow={false}
            selectedProduct={hotspot.product}
            tabIndex={-1}
          >
            <ProductTile
              key={index}
              model={hotspot.product}
              styleName="popup"
              styles={styles}
              defulatImage={noImage}
              blockId={blockId}
            />
          </Popover>
        ) : null}
    </>
  );
};

Hotspot.propTypes = {
  initialModel: PropTypes.object,
  hotspot: PropTypes.object,
  icon: PropTypes.string,
};
export default Hotspot;
