exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.33.33\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".LookbookContentBlock__ActiveOnFocusButton_visually-hidden{clip:rect(0,0,0,0);border:0;height:0;margin:-1px;overflow:hidden;padding:0;position:absolute;width:0}.LookbookContentBlock__ActiveOnFocusButton_active-on-focus:focus{clip:auto;height:auto;outline:0;overflow:auto;padding:5px 10px;white-space:nowrap;width:auto}.LookbookContentBlock__ActiveOnFocusButton_active-on-focus.LookbookContentBlock__ActiveOnFocusButton_topLeft{left:10%}.LookbookContentBlock__ActiveOnFocusButton_active-on-focus.LookbookContentBlock__ActiveOnFocusButton_topRight{right:5px;top:5px}.LookbookContentBlock__ActiveOnFocusButton_active-on-focus.LookbookContentBlock__ActiveOnFocusButton_bottomLeft{bottom:5px;left:5px}.LookbookContentBlock__ActiveOnFocusButton_active-on-focus.LookbookContentBlock__ActiveOnFocusButton_bottomRight{bottom:5px;right:5px}", ""]);

// exports
exports.locals = {
	"visually-hidden": "LookbookContentBlock__ActiveOnFocusButton_visually-hidden",
	"visuallyHidden": "LookbookContentBlock__ActiveOnFocusButton_visually-hidden",
	"active-on-focus": "LookbookContentBlock__ActiveOnFocusButton_active-on-focus",
	"activeOnFocus": "LookbookContentBlock__ActiveOnFocusButton_active-on-focus",
	"topLeft": "LookbookContentBlock__ActiveOnFocusButton_topLeft",
	"topRight": "LookbookContentBlock__ActiveOnFocusButton_topRight",
	"bottomLeft": "LookbookContentBlock__ActiveOnFocusButton_bottomLeft",
	"bottomRight": "LookbookContentBlock__ActiveOnFocusButton_bottomRight"
};